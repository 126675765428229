// import { createMedia } from "@artsy/fresnel";
import React from "react";
import {
  Image,
  Grid,
  Button,
  Container,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./header.css";
import fiveStar from "./img/5stars.png";

// const { MediaContextProvider, Media } = createMedia({
//   breakpoints: {
//     mobile: 0,
//     tablet: 768,
//     computer: 1024,
//   },
// });

export default function HomepageHeading() {
  const { t } = useTranslation(["common", "users", "frontPage"]);

  return (
    <>
      <Container className="header_textBlock" text>
        <Header
          className="header1"
          as="h1"
          content={t("header", { ns: "frontPage" })}
          inverted
        />
        <Header
          className="header2"
          as="h2"
          content={t("tagline", { ns: "frontPage" })}
          inverted
        />
        <Button
          className="RTLG_button header_button"
          primary
          size="huge"
          href="https://www.autolaborexpert.com/signup"
        >
          {t("buttons.free_trial", { ns: "frontPage" })}
          <Icon name="right arrow" />
        </Button>
      </Container>
      <Segment className="full_width">
        <Grid>
          <Grid.Column width={10}>
            <p className="header2">{t("join_us", { ns: "frontPage" })}</p>
            <p>{t("quality", { ns: "frontPage" })}</p>
          </Grid.Column>
          <Grid.Column width={4}>
            <Image src={fiveStar} width="200" />
          </Grid.Column>
        </Grid>
      </Segment>
    </>
  );

  // HomepageHeading.propTypes = {
  //   mobile: PropTypes.bool,
  // };
}
