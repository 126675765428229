import React, { useEffect } from "react";
import {
  Container,
  Header,
  Divider,
  Grid,
  Segment,
  List,
  Card,
  Button,
  Image,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./products.css";
import impact from "./img/impact-RTLG.webp";
import pic1 from "./img/RTLG1.webp";
import pic2 from "./img/RTLG2.webp";
import pic3 from "./img/RTLG3.webp";
import pic4 from "./img/RTLG4.webp";
import tools from "./img/tools.webp";

export default function RTLG() {
  const { t } = useTranslation(["common", "users", "products", "products"]);

  useEffect(() => {
    document.title = t("RTLG.header", {
      ns: "products",
    });
  }, []);

  return (
    <>
      <Container className="product-container">
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column width={10}>
              <Header as="h1" className="FAQ_header_font">
                {t("RTLG.header", {
                  ns: "products",
                })}
              </Header>
              <Header as="h3">
                {t("RTLG.header_intro", {
                  ns: "products",
                })}
              </Header>
            </Grid.Column>
            <Grid.Column width={6} className="header_button">
              <Button
                className="RTLG_button header_button"
                primary
                floated="right"
                href="https://www.autolaborexpert.com/signup"
              >
                {t("button.21_free_trial", {
                  ns: "products",
                })}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <Segment basic className="FAQ_pt2">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as="h3" className="homepage_header_font">
                  {t("RTLG.help_you", {
                    ns: "products",
                  }) + ":"}
                </Header>
                <List relaxed="very" className="homepage_text">
                  <List.Item
                    icon="check"
                    content={t("RTLG.help_you_p1", {
                      ns: "products",
                    })}
                  />
                  <List.Item
                    icon="check"
                    content={t("RTLG.help_you_p2", {
                      ns: "products",
                    })}
                  />
                  <List.Item
                    icon="check"
                    content={t("RTLG.help_you_p3", {
                      ns: "products",
                    })}
                  />
                </List>
              </Grid.Column>
              <Grid.Column floated="right" width={6}>
                <Image bordered rounded fluid src={pic1} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Header as="h1" className="comparison_FAQs">
          {t("RTLG.header_2", {
            ns: "products",
          })}
        </Header>
        <p className="homepage_text">
          {t("RTLG.header_2_p1", {
            ns: "products",
          })}
        </p>
        <Segment basic className="FAQ_pt3">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={6}>
              <Image bordered rounded fluid src={pic4} />
              </Grid.Column>
              <Grid.Column floated="right" width={8}>
                <Header as="h3" className="homepage_header_font">
                  {t("RTLG.RTLG_factors", {
                    ns: "products",
                  }) + ":"}
                </Header>
                <List relaxed="very" className="homepage_text">
                  <List.Item
                    icon="check"
                    content={t("RTLG.RTLG_1", {
                      ns: "products",
                    })}
                  />
                  <List.Item
                    icon="check"
                    content={t("RTLG.RTLG_2", {
                      ns: "products",
                    })}
                  />
                  <List.Item
                    icon="check"
                    content={t("RTLG.RTLG_3", {
                      ns: "products",
                    })}
                  />
                  <List.Item
                    icon="check"
                    content={t("RTLG.RTLG_4", {
                      ns: "products",
                    })}
                  />
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment basic>
          <Card
            fluid
            className="sign_up_card_RTLG color_layer"
            style={{
              backgroundImage: `url("${impact}")`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Grid container textAlign="center" stackable verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={8}>
                  <Header as="h1" className="card_header_font">
                    {t("RTLG.only", {
                      ns: "products",
                    })}{" "}
                    $15/
                    {t("RTLG.price", {
                      ns: "products",
                    })}
                    {"!"}
                  </Header>
                  <div className="card_button">
                    <Button
                      className="RTLG_button"
                      primary
                      href="https://www.autolaborexpert.com/signup"
                    >
                      {t("button.free_trial", {
                        ns: "products",
                      })}
                    </Button>
                    <p>
                      {t("RTLG.21_days", {
                        ns: "products",
                      })}
                    </p>
                  </div>
                </Grid.Column>
                <Grid.Column
                  floated="right"
                  width={6}
                  className="cardFinePrint"
                >
                  {/* <Image bordered rounded size="large" src={wrench} /> */}
                  <p className="finePrint">
                    {t("RTLG.check", {
                      ns: "products",
                    })}
                    <a href="/FrequentlyAskedQuestions">
                      {t("RTLG.FAQ_page", {
                        ns: "products",
                      })}
                    </a>
                    {t("RTLG.more_info", {
                      ns: "products",
                    })}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card>
        </Segment>
        <Segment basic className="features_intro">
          <Header as="h1" textAlign="center">
            {t("RTLG.features", {
              ns: "products",
            })}
            {":"}
          </Header>
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as="h2">
                  {t("RTLG.discover", {
                    ns: "products",
                  }) + ":"}
                </Header>
                <List relaxed="very" className="homepage_text">
                  <List.Item
                    icon="check"
                    content={t("RTLG.access", {
                      ns: "products",
                    })}
                  />
                  <List.Item
                    icon="check"
                    content={t("RTLG.labor_times", {
                      ns: "products",
                    })}
                  />
                  <List.Item
                    icon="check"
                    content={t("RTLG.flexibility", {
                      ns: "products",
                    })}
                  />
                  <List.Item
                    icon="check"
                    content={t("RTLG.invoices", {
                      ns: "products",
                    })}
                  />
                  <List.Item
                    icon="check"
                    content={t("RTLG.packages", {
                      ns: "products",
                    })}
                  />
                  <List.Item
                    icon="check"
                    content={t("RTLG.misc_charges", {
                      ns: "products",
                    })}
                  />
                  <List.Item
                    icon="check"
                    content={t("RTLG.customize", {
                      ns: "products",
                    })}
                  />
                  <List.Item
                    icon="check"
                    content={t("RTLG.labor_rate", {
                      ns: "products",
                    })}
                  />
                  {/* <List.Item icon="check"
                  content={t("RTLG.updates", {
                    ns: "products",
                  })} /> */}
                </List>
              </Grid.Column>
              <Grid.Column floated="right" width={6}>
              <Image bordered rounded fluid src={tools} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment basic className="features2">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={6}>
              <Image bordered rounded fluid src={pic3} />
              </Grid.Column>
              <Grid.Column floated="right" width={8}>
                <Header as="h3" className="homepage_header_font"></Header>
                <List relaxed="very" className="homepage_text">
                  <List.Item icon="check">
                    <b>
                      {t("RTLG.pricing_intro", {
                        ns: "products",
                      }) + ": "}
                    </b>
                    {t("RTLG.pricing", {
                      ns: "products",
                    })}{" "}
                  </List.Item>
                  <List.Item icon="check">
                    <b>
                      {t("RTLG.easy_intro", {
                        ns: "products",
                      }) + ": "}
                    </b>
                    {t("RTLG.easy", {
                      ns: "products",
                    })}{" "}
                  </List.Item>
                  <List.Item icon="check">
                    <b>
                      {t("RTLG.resource_intro", {
                        ns: "products",
                      }) + ": "}
                    </b>
                    {t("RTLG.resource", {
                      ns: "products",
                    })}{" "}
                  </List.Item>
                  {/* <List.Item icon="check"><b>{t("RTLG.access_anywhere_intro", {
                    ns: "products",
                  }) + ": "}</b>{t("RTLG.access_anywhere", {
                    ns: "products",
                  })} </List.Item> */}
                  {/* <List.Item icon="check"><b>{t("RTLG.operating_system_intro", {
                    ns: "products",
                  }) + ": "}</b>{t("RTLG.operating_system", {
                    ns: "products",
                  })} </List.Item> */}
                  <List.Item icon="check">
                    <b>
                      {t("RTLG.customize_features_intro", {
                        ns: "products",
                      }) + ": "}
                    </b>
                    {t("RTLG.customize_features", {
                      ns: "products",
                    })}{" "}
                  </List.Item>
                  <List.Item icon="check">
                    <b>
                      {t("RTLG.invisible_updates_intro", {
                        ns: "products",
                      }) + ": "}
                    </b>
                    {t("RTLG.invisible_updates", {
                      ns: "products",
                    })}{" "}
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        {/* <Segment basic className="sign_up_card">
        <Header as="h3" className="homepage_header_font" textAlign="center">
          {t("RTLG.RTLG_FAQs", {
            ns: "products",
          })}
        </Header>
        <Card.Group centered>
          <Card></Card>
          <Card></Card>
          <Card></Card>
        </Card.Group>
      </Segment> */}
      </Container>
      <Container
        textAlign="center"
        className="extra_footer_RTLG"
        style={{
          backgroundImage: `url("${pic2}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Segment basic>
          <Header as="h1" className="extra_footer_RTLG">
            {t("RTLG.only", {
              ns: "products",
            })}{" "}
            $15/
            {t("RTLG.price", {
              ns: "products",
            })}
            {"!"}
          </Header>
          <Header as="h3" className="extra_footer_RTLG">
            {t("button.2_mins", {
              ns: "products",
            })}
          </Header>
          <Button
            className="RTLG_button"
            primary
            href="https://www.autolaborexpert.com/signup"
          >
            {t("button.21_free_trial", {
              ns: "products",
            })}
          </Button>
          <br></br>
          <p>
            {t("button.no_cc", {
              ns: "products",
            })}
          </p>
          <p className="finePrint">
            {t("RTLG.check", {
              ns: "products",
            })}
            <a href="/FrequentlyAskedQuestions">
              {t("RTLG.FAQ_page", {
                ns: "products",
              })}
            </a>
            {t("RTLG.more_info", {
              ns: "products",
            })}
          </p>
        </Segment>
      </Container>
    </>
  );
}
