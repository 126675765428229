import React from "react";
import { Container } from "semantic-ui-react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import FAQs from "../../features/contact/FAQs";
import HomePage from "../../features/home/HomePage";
import RTLG from "../../features/products/RTLG";
import Pro from "../../features/products/RTP";
import Expert from "../../features/products/AE";
import Contact from "../../features/contact/ContactUs";
import ContactUsConfirm from "../../features/contact/ContactUsConfirm";
import Corrections from "../../features/contact/Corrections";
import CorrectionsConfirm from "../../features/contact/CorrectionsConfirm";
import AboutUs from "../../features/about/AboutUs";
import Privacy from "../../features/about/Privacy";
import AEform from "../../features/products/AEform";
import AEformConfirm from "../../features/products/AEformConfirm";
import Download from "../../features/download/download";
import FourOhFour from "../../features/errors/FourOhFour";

export default function App() {
  return (
    <>
      <ToastContainer position="bottom-right" hideProgressBar />
      <>
        <NavBar />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/Real-TimeLaborGuide" component={RTLG} />
          <Route exact path="/real-time-labor-guide" component={RTLG} />
          <Route exact path="/real-time-labor-guide-pricing" component={RTLG} />
          <Route
            exact
            path="/real-time-labor-guide-features"
            component={RTLG}
          />
          <Route exact path="/Real-TimePro" component={Pro} />
          <Route exact path="/real-time-pro" component={Pro} />
          <Route exact path="/real-time-pro-pricing" component={Pro} />
          <Route exact path="/real-time-pro-features" component={Pro} />
          <Route exact path="/AutomotiveExpert" component={Expert} />
          <Route exact path="/automotive-expert" component={Expert} />
          <Route exact path="/automotive-expert-pricing" component={Expert} />
          <Route exact path="/automotive-expert-features" component={Expert} />
          <Route exact path="/FrequentlyAskedQuestions" component={FAQs} />
          <Route exact path="/faq" component={FAQs} />
          <Route exact path="/ContactUs" component={Contact} />
          <Route exact path="/contact-us" component={Contact} />
          <Route
            exact
            path="/ContactUs/ContactUsConfirm"
            component={ContactUsConfirm}
          />
          <Route exact path="/CorrectionsForm" component={Corrections} />
          <Route exact path="/correction-form" component={Corrections} />
          <Route
            exact
            path="/CorrectionsForm/CorrectionsConfirm"
            component={CorrectionsConfirm}
          />
          <Route exact path="/AboutUs" component={AboutUs} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/PrivacyStatement" component={Privacy} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/AutomotiveExpert/FreeTrial" component={AEform} />
          <Route exact path="/automotive-expert/free-trial" component={AEform} />
          <Route
            exact
            path="/AutomotiveExpert/FreeTrial/AEformConfirm"
            component={AEformConfirm}
          />
          <Route
            exact
            path="/download/3B387E40-1F21-4DEF-8F40-A2A19DBB402F"
            component={Download}
          />
          <Redirect
            path="/download/ae"
            to="/download/3B387E40-1F21-4DEF-8F40-A2A19DBB402F?productID=5735E7F4-7827-4293-82F5-46D238ED8582"
          />
          <Redirect
            path="/download/rtlg"
            to="/download/3B387E40-1F21-4DEF-8F40-A2A19DBB402F?productID=FD1684DE-7C46-4494-B5B8-F1F279A0158A"
          />
          <Redirect to="/" />
          <Route
            path={"/(.+)"}
            render={() => <Route component={FourOhFour} />}
          />
        </Switch>
      </>
      <Footer />
    </>
  );
}
