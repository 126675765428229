import React from "react";
import { Container, } from "semantic-ui-react";
//import { useTranslation } from "react-i18next";
// import './contact.css'

export default function AboutUs() {
    //const { t } = useTranslation(["common", "users", "frontPage"]);

    return(
        <Container className="product-container">

        </Container>
    );
}